<template>
    <div id="createnewapplication" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">{{ $t("app_createNewApplication") }}</h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("app_createNewApplicationSubTitle") }}</span>
                            </div>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper"></div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            />
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) " x="11" y="4" width="2" height="16" rx="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title" data-cy="newApplicationHeadInfoTitle">
                                                    {{ $t("app_applicationInfo") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <div class="form-group row validated">
                                                        <div class="col-lg-6">
                                                            <label>{{ $t("app_applicationName") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input id="newApplicationNameInput" type="text" class="form-control" :placeholder="$t('app_inputInfoApplicationName')" v-model="newApplicationNameInputVal" />
                                                            </div>
                                                            <div v-if="!$v.newApplicationNameInputVal.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="!$v.newApplicationNameInputVal.minLen" class="invalid-feedback">
                                                                {{ $t("error_minLengthMsg", [$v.newApplicationNameInputVal.$params.minLen.min]) }}
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("app_detailInfoApplicationName") }}</span>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="">{{ $t("app_applicationTarget") }} *</label>
                                                            <div class="input-group">
                                                                <select id="newApplicationTargetSelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                            </div>
                                                            <div v-if="!$v.newApplicationTargetSelectVal.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("app_detailInfoApplicationTarget") }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-12">
                                                            <label for="applicationDescription">{{ $t("app_applicationDescription") }}</label>
                                                            <textarea id="newApplicationDescriptionTextArea" class="form-control" rows="3" v-model="newApplicationDescriptionTextAreaVal"></textarea>
                                                            <span class="form-text text-muted">{{ $t("app_detailInfoApplicationDescription") }}</span>
                                                        </div>
                                                    </div>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <label class="kt-checkbox kt-margin-r-20">
                                                                <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                                <span></span>
                                                            </label>
                                                            <button id="newApplicationCreateButton" @click="onCreateApplication" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid">
                                                                {{ $t("common_add") }}
                                                            </button>

                                                            <button id="newApplicationCancelButton" @click="onCancel" type="reset" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
const _applicationTargets = require("../../constants/applicationTargets");
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            newApplicationNameInputVal: "",
            applicationTargets: _applicationTargets.list,
            newApplicationTargetSelectVal: "",
            createAnother: false,
            newApplicationDescriptionTextAreaVal: ""
        };
    },
    created: function() {
        console.log("Component(NewApplication)::created() - called");
        this.createAnother = this.createAnotherApplication;
        this.setCreateAnotherApplication(false);
    },
    mounted: function() {
        console.log("Component(NewApplication)::mounted() - Init metronic layout");
        KTLayout.init();

        this.initApplicationTypeSelect2();
    },
    destroyed: function() {
        console.log("Component(NewApplication)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        newApplicationNameInputVal: {
            required,
            minLen: minLength(4)
        },

        newApplicationTargetSelectVal: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["createAnotherApplication"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["createUserApplication", "setCreateAnotherApplication"]),

        // Function called to init application types select2
        initApplicationTypeSelect2() {
            var self = this;
            // Ascending sort order Select2 data of units
            const data = _.orderBy(this.applicationTargets, [], ["asc"]);
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newApplicationTargetSelect"));
            $("#newApplicationTargetSelect")
                .select2({
                    placeholder: i18n.t("app_detailInfoApplicationTarget"),
                    data: data,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.newApplicationTargetSelectVal = $(this).val();
                });
        },

        // Function called when user click on the "Cancel" button
        onCancel() {
            console.log("Component(NewApplication)::onCancel() - called");
            this.$router.push({ name: "applications" });
        },

        // Function called when user click on the "Create Application" button
        onCreateApplication() {
            console.log("Component(NewApplication)::onCreateApplication() - called");
            let applicationData = {
                name: this.newApplicationNameInputVal,
                target: this.newApplicationTargetSelectVal,
                description: this.newApplicationDescriptionTextAreaVal,
                createAnother: this.createAnother,
            };
            // Create the new application in the backend
            this.createUserApplication(applicationData);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
